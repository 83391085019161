// PUBLIC USER ZONE ROUTES
import React from 'react';
import { Routes, Route } from "react-router-dom";
import { ClientLayout } from "../layouts"
import { Home, Contact, Courses, Blog, Post } from "../pages/web";


export function WebRouter() {
    // Function charging layout:
    const loadLayout = (Layout, Page) => {
        return (
            <Layout>
                <Page />
            </Layout>
        )
    }
    return (
        <Routes>
            <Route path="/" element={loadLayout(ClientLayout, Home)} />
            <Route path="/courses" element={loadLayout(ClientLayout, Courses)} />
            <Route path="/contact" element={loadLayout(ClientLayout, Contact)} />
            <Route path="/blog" element={loadLayout(ClientLayout, Blog)} />
            {/*Dynamic Route with variable 'path'.*/}
            <Route path="/blog/:path" element={loadLayout(ClientLayout, Post)} />

        </Routes>
    )
}
